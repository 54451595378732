.App {
  text-align: center;
}

@font-face {
  font-family: "NeonLight";
  font-style: normal;
  font-weight: normal;
  src: url("NeonLight-Regular.woff2");
}

.App-header {
  padding: 5vh 5vh;
}

.App-header-logo {
  color: black;
  font-size: calc(10px + 8vmin);
  font-family: "NeonLight", serif;
}

.App-head {
  flex: 1;
  height: 40vmin;
  pointer-events: none;
}

.App-head-jason {
  height: 40vmin;
  pointer-events: none;
}

.App-head-trevor {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-head-jason {
    animation: App-head-spin-clockwise infinite 5s linear;
  }

  .App-head-trevor {
    animation: App-head-spin-counter-clockwise infinite 5s linear;
  }
}

.App-content {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3vw;
  color: black;
}

@keyframes App-head-spin-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-head-spin-counter-clockwise {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.App-heads {
  display: flex;
  flex-direction: row;
  gap: 15vw;
  justify-content: space-evenly;
}
